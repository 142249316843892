import { HeartIcon } from "@icons/HeartIcon";
import { Button } from "ui-2";
import { PetArchivedModal } from "./PetArchivedModal";
import { useState } from "react";
import { DeleteProfileModal } from "./DeleteProfileModal";
import { useToastConfig } from "@components/toast";
import { useMyPets } from "@hooks/useMyPet";
import { MY_PETS } from "@constants/myPets";
import { useTranslation } from "react-i18next";
import { ACTION_NEEDED, PET_HIDE_REASONS, PET_STATUS } from "@constants/index";
import { Trans } from "react-i18next";
import { usePromise } from "utils";
import { setPetStatus } from "@api-calls/useUpdatePetStatus";

export default function DeceasedPetComponent({
  petId,
  petName,
}: {
  petId: string;
  petName: string;
}) {
  const { t } = useTranslation();
  const { showToast } = useToastConfig();
  const [isArchivedModalOpen, setIsArchivedModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { setIsViewRememberedPets, refetchPetList } = useMyPets();
  const [{ isPending }, { exec: setPetStatusData }] = usePromise(setPetStatus);

  const onShowRemoveProfile = () => {
    setIsDeleteModalOpen(true);
  };

  const onShowArchiveProfile = () => {
    setIsArchivedModalOpen(true);
  };

  const onRemoveProfile = async () => {
    const response = await setPetStatusData({
      petId: petId,
      petStatusId: PET_STATUS.DELETED,
      petRemovalReason: PET_HIDE_REASONS.DECEASED,
    });

    if (response?.status === 200) {
      showToast({
        description: t(MY_PETS.DECEASED_PET.PROFILE_REMOVED),
        type: "success",
      });
      refetchPetList();
    } else {
      showToast({
        description: t(MY_PETS.DECEASED_PET.REMOVE_PROFILE_ERROR),
        type: "error",
      });
    }

    setIsDeleteModalOpen(false);
  };

  const onArchiveProfile = async (goToRememberedPets: boolean) => {
    const response = await setPetStatusData({
      petId: petId,
      petStatusId: PET_STATUS.REMEMBERED,
      petRemovalReason: PET_HIDE_REASONS.DECEASED,
    });

    if (response?.status === 200) {
      refetchPetList();
      if (goToRememberedPets) {
        setIsViewRememberedPets(true);
      }
    } else {
      showToast({
        description: t(MY_PETS.DECEASED_PET.ARCHIVE_PROFILE_ERROR),
        type: "error",
      });
    }

    setIsArchivedModalOpen(false);
  };

  return (
    <div className="mx-6 flex max-w-[552px] flex-col gap-6 rounded-lg border-brand-color-library-gray-100 p-6 shadow-dp2 sm:mx-0">
      <div className="text-2xl font-bold text-brand-color-library-gray-900">
        {t(ACTION_NEEDED)}
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-base text-brand-color-library-gray-900">
          <Trans
            i18nKey={MY_PETS.DECEASED_PET.PROFILE_REMOVED_SUB_TEXT}
            values={{ petName }}
            components={{ b: <b /> }}
          />
        </div>
        <div className="flex justify-center">
          <HeartIcon width={48} height={48} />
        </div>
      </div>
      <div className="text-base text-brand-color-library-gray-900">
        <Trans
          i18nKey={MY_PETS.DECEASED_PET.ARCHIVE_PROFILE_QUESTION}
          values={{ petName }}
          components={{ b: <b /> }}
        />
      </div>
      <div className="flex flex-col-reverse justify-center gap-4 sm:grid sm:grid-cols-2">
        <Button variant="blueOutlined" onClick={onShowRemoveProfile}>
          {t(MY_PETS.DECEASED_PET.REMOVE_PROFILE_CTA)}
        </Button>
        <Button onClick={onShowArchiveProfile}>
          {t(MY_PETS.DECEASED_PET.ARCHIVE_PROFILE_CTA)}
        </Button>
      </div>

      <PetArchivedModal
        petName={petName}
        isOpen={isArchivedModalOpen}
        onClose={() => setIsArchivedModalOpen(false)}
        onConfirm={onArchiveProfile}
        isLoading={isPending}
      />
      <DeleteProfileModal
        petName={petName}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={onRemoveProfile}
        isLoading={isPending}
      />
    </div>
  );
}
