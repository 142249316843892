export const MY_PETS = {
  HEADING: "myPets.heading",
  CAROUSAL_HEADER: "myPets.carousalHeader",
  CAROUSAL_ADD_PET: "myPets.carousalAddPet",
  ADDPET: "myPets.addPet",

  NO_PETS: {
    LABEL1: "myPets.NoPets.label1",
    LABEL2: "myPets.NoPets.label2",
    BUTTON_LABEL: "myPets.carousalAddPet",
  },
  PROFILE: {
    HEADING: "myPets.profile.heading",
    LABEL1: "myPets.profile.label1",
    LABEL2: "myPets.profile.label2",
    EDIT: "myPets.profile.edit",
    NO_BREED: "myPets.profile.noBreed",
    NONE: "myPets.profile.none",
    DIET_NAVIGATION: "myPets.profile.dietNavigation",
  },
  VET: {
    HEADING: "myPets.vet.heading",
  },
  MILESTONES: {
    HEADING: "myPets.milestones.heading",
    LIFESTAGE: "myPets.milestones.lifestage",
    PUPPY: "myPets.milestones.puppy",
    ADULT_DOG: "myPets.milestones.adultDog",
    SENIOR_DOG: "myPets.milestones.seniorDog",
    KITTEN: "myPets.milestones.kitten",
    ADULT_CAT: "myPets.milestones.adultCat",
    SENIOR_CAT: "myPets.milestones.seniorCat",
    UPCOMING_MILESTONES: "myPets.milestones.upcomingMilestones",
    COMPLETED_MILESTONES: "myPets.milestones.completedMilestones",
    NO_UNCOMPLETED_MILESTONES: "myPets.milestones.noUncompletedMilestones",
    NO_COMPLETED_MILESTONES: "myPets.milestones.noCompletedMilestones",
  },
  DIET: {
    HEADING: "myPets.diet.heading",
    SELECT_FOOD: "myPets.diet.selectFood",
    FOOD_QUIZ_QUESTION: "myPets.diet.foodQuizQuestion",
    FOOD_QUIZ_LINK: "myPets.diet.foodQuizLink",
    FEEDING_GUIDE: "foodSelect.feedingGuide",
    EDIT: "myPets.diet.edit",
  },
  REMEMBRED_PETS: {
    HEADING: "myPets.rememberedPets.rememberedPetsHeading",
    LABEL: "myPets.rememberedPets.rememberedPetsLabel",
    VIEW_MY_PETS: "myPets.rememberedPets.viewMyPets",
  },
  ADD_EDIT_PET: {
    SMALL_WEIGHT_RANGE_DOG: "myPets.addEditPet.smallWeightRangeDog",
    MEDIUM_WEIGHT_RANGE_DOG: "myPets.addEditPet.mediumWeightRangeDog",
    LARGE_WEIGHT_RANGE_DOG: "myPets.addEditPet.largeWeightRangeDog",
    SMALL_WEIGHT_RANGE_CAT: "myPets.addEditPet.smallWeightRangeCat",
    MEDIUM_WEIGHT_RANGE_CAT: "myPets.addEditPet.mediumWeightRangeCat",
    LARGE_WEIGHT_RANGE_CAT: "myPets.addEditPet.largeWeightRangeCat",
    MALE: "myPets.addEditPet.Male",
    FEMALE: "myPets.addEditPet.Female",
  },
  SHOW_HIDE_POPUP: {
    LOADING: "myPets.showHidePopup.loading",
    SHARE_PROFILE: "myPets.showHidePopup.shareProfile",
    UNHIDE_PET: "myPets.showHidePopup.unhidePet",
    HIDE_PET: "myPets.showHidePopup.hidePet",
    DELETE_PET: "myPets.showHidePopup.deletePet",
  },
  SHARE_PET_PROFILE: {
    PET_TITLE: "myPets.sharePetProfile.petTitle",
    NICKNAME: "myPets.sharePetProfile.nickname",
    BIRTHDATE: "myPets.sharePetProfile.birthdate",
    BREED: "myPets.sharePetProfile.breed",
    SIZE: "myPets.sharePetProfile.size",
    GENDER: "myPets.sharePetProfile.gender",
    DIET: "myPets.sharePetProfile.diet",
    FEEDING_GUIDE: "myPets.sharePetProfile.feedingGuide",
    SHARE_PET_PROFILE: "myPets.sharePetProfile.sharePetProfile",
    SHARE_PET_PROFILE_ERROR: "myPets.sharePetProfile.sharePetProfileError",
  },
  DECEASED_PET: {
    DELETE_PROFILE_TITLE: "myPets.deceasedPet.deleteProfileTitle",
    DELETE_PRIMARY_TEXT: "myPets.deceasedPet.deletePrimaryText",
    DELETE_SUB_TEXT: "myPets.deceasedPet.deleteSubText",
    DELETE_PET_PROFILE: "myPets.deceasedPet.deletePetProfile",
    ARCHIVE_PROFILE_TITLE: "myPets.deceasedPet.archiveProfileTitle",
    ARCHIVE_PROFILE_TEXT: "myPets.deceasedPet.archiveProfileText",
    ARCHIVE_PROFILE_SUB_TEXT: "myPets.deceasedPet.archiveProfileSubText",
    GO_TO_REMEMBERED_PETS: "myPets.deceasedPet.goToRememberedPets",
    PROFILE_REMOVED: "myPets.deceasedPet.profileRemoved",
    PROFILE_REMOVED_SUB_TEXT: "myPets.deceasedPet.profileRemovedSubText",
    ARCHIVE_PROFILE_QUESTION: "myPets.deceasedPet.archiveProfileQuestion",
    ARCHIVE_PROFILE_CTA: "myPets.deceasedPet.archiveProfileCTA",
    REMOVE_PROFILE_CTA: "myPets.deceasedPet.removeProfileCTA",
    ARCHIVE_PROFILE_ERROR: "myPets.deceasedPet.archiveProfileError",
    REMOVE_PROFILE_ERROR: "myPets.deceasedPet.removeProfileError",
  },
  ADD_PETS_FROM_SNOWFLAKE_MODAL: {
    TITLE: "myPets.addPetsFromSnowflakeModal.title",
    DESCRIPTION: "myPets.addPetsFromSnowflakeModal.description",
    EDIT_LATER: "myPets.addPetsFromSnowflakeModal.editLater",
    ADD_SELECTED_PETS: "myPets.addPetsFromSnowflakeModal.addSelectedPets",
    START_FROM_SCRATCH: "myPets.addPetsFromSnowflakeModal.startFromScratch",
    START_FROM_SCRATCH_MESSAGE: "myPets.addPetsFromSnowflakeModal.startFromScratchMessage",
  },
  PET_ADD_UPDATE_ITEM: {
    NEW: "myPets.petAddUpdateItem.new",
    NICKNAME: "myPets.petAddUpdateItem.nickname",
    BIRTHDAY: "myPets.petAddUpdateItem.birthday",
    ADOPTION_DATE: "myPets.petAddUpdateItem.adoptionDate",
    SIZE: "myPets.petAddUpdateItem.size",
    GENDER: "myPets.petAddUpdateItem.gender",
    BREED: "myPets.petAddUpdateItem.breed",
    FOOD: "myPets.petAddUpdateItem.food",
    LIFE_STAGE: "myPets.petAddUpdateItem.lifeStage",
    VETERINARIAN: "myPets.petAddUpdateItem.veterinarian",
  },
  PETS_ADDED_MODAL: {
    TITLE: "myPets.petsAddedModal.title",
    DESCRIPTION: "myPets.petsAddedModal.description",
    ADD_ANOTHER_PET: "myPets.petsAddedModal.addAnotherPet",
  },
  REMOVE_PETS_MODAL: {
    TITLE: "myPets.removePetsModal.title",
    DESCRIPTION: "myPets.removePetsModal.description",
    WARNING: "myPets.removePetsModal.warning",
    GO_BACK: "myPets.removePetsModal.goBack",
    REMOVE_AND_CONTINUE: "myPets.removePetsModal.removeAndContinue",
  },
  START_FROM_SCRATCH_MODAL: {
    TITLE: "myPets.startFromScratchModal.title",
    DESCRIPTION: "myPets.startFromScratchModal.description",
    WARNING: "myPets.startFromScratchModal.warning",
    GO_BACK: "myPets.startFromScratchModal.goBack",
    REMOVE_AND_CONTINUE: "myPets.startFromScratchModal.removeAndContinue",
  },
  PETS_UPDATES_NOTIFICATION: {
    TITLE: "myPets.petsUpdatesNotification.title",
    DESCRIPTION: "myPets.petsUpdatesNotification.description",
    SEE_CHANGES: "myPets.petsUpdatesNotification.seeChanges",
  },
  PETS_UPDATES_NOTIFICATION_MODAL: {
    TITLE: "myPets.petsUpdatesNotificationModal.title",
    WHAT_CHANGED: "myPets.petsUpdatesNotificationModal.whatChanged",
    EDIT_INFO: "myPets.petsUpdatesNotificationModal.editInfo",
    DESCRIPTION: "myPets.petsUpdatesNotificationModal.description",
    SEE_CHANGES: "myPets.petsUpdatesNotificationModal.seeChanges",
  },
};
