import React, {
    useState,
    useEffect,
    useContext,
    createContext,
    useRef,
  } from "react";
  import axios from "axios";
  import { useApi } from "utils";
  import { API_URLS } from "../constants/apiUrls";
  import { Auth, useAuth } from "./useAuth";
  import { AUTHENTICATION_STATUS } from "../constants";
  import { PetType } from "../../types";
  
  const initData = {
    hphPets: [],
    snowflakePets : {
      rememberedPets :[],
      newPets : [],
      updatedPets : []
    },
    isSfDataLoading : true,
    fetchSnowflakePets : () => void 0
  }

  interface PetObject {
    rememberedPets : Array<PetType>;
    newPets : Array<PetType>;
    updatedPets : Array<PetType>;
  }

  interface Petdata {
    hphPets: Array<PetType>;
    snowflakePets: PetObject;
    isSfDataLoading: boolean;
    fetchSnowflakePets: () => void;
  }

  const SnowFlakeContext = createContext<Petdata>(initData);
  
  export function SnowflakeDataProvider({ isEnabled ,children }: { isEnabled: boolean, children: React.ReactNode }) {
    const config = useSnowflakeData(isEnabled);
    return (
      <SnowFlakeContext.Provider
        value={{...config}}
      >
        {children}
      </SnowFlakeContext.Provider>
    );
  }
  
  export const useSnowFlakePets = () => {
    return useContext<Petdata>(SnowFlakeContext);
  };

  const petLimitCheck = (petTotalCount : number,limit:number, sfData : PetObject) => {
    if(sfData?.newPets?.length && limit){
      const diff = petTotalCount != (undefined || null ) && limit - petTotalCount;
     return diff;
    }
    return null;
  }
  
  function useSnowflakeData(snowFlake : boolean) {
    if(!snowFlake){
      return {...initData, isSfDataLoading : false}
    }
    const [hphPets, setHphPets] = useState<Array<PetType>>([]);
    const [snowflakePets, setSnowflakePets] = useState({
      rememberedPets :[],
      newPets : [],
      updatedPets : []
    });
    const [isSfDataLoading, setSfDataLoading] = useState(true);
    const abortControllerRef = useRef<AbortController | null>(null);
    const auth: Auth = useAuth();
    const { exec: fetchSnowflakePets } = useApi(() => {
      setSfDataLoading(true);
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      if(auth.user?.profile?.email){
        return axios
        .get(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, {
          signal: abortController.signal,
          headers: {  getsfdata : true , email_id : auth.user.profile.email}
        }).then((apiResponse) => {
            if (apiResponse.data.serviceStatus.code === 200) {
              setHphPets(apiResponse.data.data);
              const diff = petLimitCheck(apiResponse.data?.metadata?.petTotalCount,apiResponse.data?.metadata?.limit,apiResponse.data?.metadata?.sfData);
              if(apiResponse.data?.metadata?.sfData?.newPets?.length){
                if(diff && diff > 0){
                  apiResponse.data.metadata.sfData.newPets = apiResponse.data?.metadata?.sfData?.newPets.slice(0,diff);
                }else{
                  apiResponse.data.metadata.sfData.newPets = [];
                }
              }
              apiResponse.data?.metadata?.sfData && setSnowflakePets(apiResponse.data.metadata.sfData);
              setSfDataLoading(false);
            }else{
              setSfDataLoading(false);
            }
          });
      }
    });
  
    useEffect(() => {
      abortControllerRef.current?.abort();
      if (auth.status === AUTHENTICATION_STATUS.AUTHENTICATED && auth.user?.profile.email) {
        fetchSnowflakePets();
      }
      return () => {
        abortControllerRef.current?.abort();
      };
    }, [auth.status]);
    return {
        hphPets,
        snowflakePets,
        isSfDataLoading,
        fetchSnowflakePets
    };
  }
  